<template>
  <BaseButton
    keyTextButtonTranslate="configPhone"
    :handlerClick="goToConfigPhone"
  />
</template>

<script>
import { userRoutesName } from "@/router/names";

export default {
  name: "ConfigPhoneBtn",
  components: {
    BaseButton: () => import("@/components/shared/BaseButtons/Index"),
  },
  methods: {
    goToConfigPhone() {
      this.$router.push({
        name: userRoutesName.SETTINGS.name,
        params: { fromRoute: { name: this.$route.name } },
      });
    },
  },
};
</script>
